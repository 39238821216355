import styles from './Home.module.scss';
import c from "./assets/c.png";
import html from "./assets/html.png"
import js from "./assets/js.png"
import py from "./assets/py.png"
import vue from "./assets/vue.png"
import { FaLinkedinIn } from 'react-icons/fa';
import { PopupButton, InlineWidget } from "react-calendly";

function App() {
  return (
    <div className={styles.container}>
      <main>
        <h1>Nathanael Leyton</h1>
        <div className={styles.card}>
          <h1>Background</h1>
          <h2>Software Engineer with a B.S. in computer science and full-stack experience in a hybrid remote-office environment. Work experience includes designing responsive UI/UX applications, HTML, CSS, Javascript, and Java coding, creating and utilizing algorithms, building and shipping production React, Vue, and Angular applications, Git, database management, and AWS and Cloudfront management and deployments.</h2>
          <div className={styles.grid}>
            <div>
              <h3>2022 - Present</h3>
              <p>Full Stack Engineer - Texas Capital Bank</p>
            </div>
            <div>
              <h3>2019 - 2022</h3>
              <p>Lead Full Stack Engineer - Rave (Contract)</p>
              <p>Full Stack Engineer - Rave I.T. (Contract)</p>
            </div>
            <div>
              <h3>2017 - 2020</h3>
              <p>B.S., Computer Science - NYU</p>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <h1>Skills</h1>
          <div>
            <div className={styles.skillsGrid}>
              <div className={styles.skills}>
                <p>Javascript</p>
                <img src={js} alt="Javascript skill level" width="200" height="25"/>
              </div>
              <div className={styles.skills}>
                <p>Angular</p>
                <img src={js} alt="HTML and CSS skill level" width="200" height="25"/>
              </div>
              <div className={styles.skills}>
                <p>React</p>
                <img src={vue} alt="React.js skill level" width="200" height="25"/>
              </div>
            </div>
            <div className={styles.skillsGrid}>
              <div className={styles.skills}>
                <p>Vue</p>
                <img src={vue} alt="Vue.js skill level" width="200" height="25"/>
              </div>
              <div className={styles.skills}>
                <p>Java</p>
                <img src={c} alt="Java skill level" width="200" height="25"/>
              </div>
              <div className={styles.skills}>
                <p>Databases</p>
                <img src={py} alt="Python skill level" width="200" height="25"/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.portfolioCard}>
          <h1>Freelance Portfolio</h1>
          <p>Click a link to view a page</p>
          <div className={styles.portfolioGrid}>
            <a href="https://itsgoodms.com">Its Good Marketing</a>
            <iframe title="Its Good Marketing Services" className={styles.portfolioFrame} frameBorder="0" src="https://itsgoodms.com"/>
          </div>
          <div className={styles.portfolioGrid}>
            <a href="https://tzmedia.us">TZ Media</a>
            <iframe title="TZ Media" className={styles.portfolioFrame} frameBorder="0" src="https://tzmedia.us"/>
          </div>
          <div className={styles.portfolioGrid}>
            <a href="https://heliosenergy.us">Helios Energy</a>
            <iframe title="Helios Solar" className={styles.portfolioFrame} frameBorder="0" src="https://heliosenergy.us"/>
          </div>
          <div className={styles.portfolioGrid}>
            <a href="https://raveenergy.net">Rave Energy</a>
            <iframe title="Rave Energy" className={styles.portfolioFrame} frameBorder="0" src="https://raveenergy.net"/>
          </div>
          <div className={styles.portfolioGrid}>
            <a href="https://rave.partners">Rave Partners</a>
            <iframe title="Rave Partners" className={styles.portfolioFrame} frameBorder="0" src="https://rave.partners"/>
          </div>
        </div>
        <div className={styles.card}>
            <p style={{textAlign: "center", margin: '0'}}><a className={styles.phone} href="tel:+15125374699">(512)537-4699</a></p>
        </div>
      </main>
      <footer className={styles.footer}>
        <a href="https://www.linkedin.com/in/nathanael-leyton/">
          <FaLinkedinIn className={styles.socialLinks}/>
        </a>
        <p>Designed and Coded by <strong>Nathanael Leyton</strong></p>
      </footer> 
      <PopupButton className={styles.popup} url="https://calendly.com/ngl2896?hide_landing_page_details=1" rootElement={document.getElementById("root")} text="Click here to schedule!"/>
    </div>
  );
}

export default App;
